.pdf-creation-form {
    .MuiBox-root { width: 920px; } 
    .MuiList-root:not(style) { width: 100%; }
    .MuiListItem-root { 
        padding-left: 0; 
        padding-right: 0;
    }
    .checkbox_and_text {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        .MuiTextField-root {
            width: 400px;
            align-self: flex-end;
        }
    }
    .MuiFormControl-root { 
        width: 400px;
        margin: 8px 0;
    }
}

@media screen and (max-width: 1020px) {
    .pdf-creation-form .MuiBox-root { width: 100%; }
}
@media screen and (max-width: 960px) {
    .pdf-creation-form { 
        .MuiFormControl-root { width: 100%; }
        .MuiFormGroup-root, .MuiAutocomplete-root, .MuiAutocomplete-popper { width: calc(50% - 2rem); }
    }
    .checkbox_and_text .MuiFormControlLabel-root { 
        width: 54%; 
        padding-right: 2rem;
    }
}

@media screen and (max-width: 840px) {
    .pdf-creation-form {
        .MuiFormGroup-root, .MuiAutocomplete-root, .MuiAutocomplete-popper { width: 100%; }
    }
}

@media screen and (max-width: 760px) {
    .pdf-creation-form .MuiList-root { margin: 0; }
    .pdf-creation-form {
        
        .checkbox_and_text {
            flex-flow: column;
            justify-content: flex-start;
            margin-bottom: -1.5rem;
            .MuiFormControlLabel-root {
                width: 100%;
                margin: 0 0 0 0.5rem;
            }
            .MuiTextField-root { width: 100%; }
        }
    }
}