@use '../assets/css/--colors.module.scss' as color;

header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 0.75rem 1rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    
    .MuiButtonBase-root { display: none; }
    nav {
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        list-style: none;
        
        a, button {
            background: transparent;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            cursor: pointer;
            color: color.$color-secondary-brblue;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            padding: 0.35rem;
            border: 1px solid transparent;
            border-radius: 4px;
            margin: 0 0.5rem;
            
            &:hover {
                border-color: #B2B5C2;
                color: color.$color-neutral-blue-60;
                text-decoration: none;
            }
            &.item-current { 
                color: color.$color-neutral-blue-60;
                border-color: transparent;
                &:hover {cursor: default;}
            }
        }
    }
}
