$color-primary-blue: #0F4696; /* B100 */
$color-primary-text: #19214D; /* NB100 */
$color-primary-dblue: #23376C; /* DB50 */

$color-secondary-green: #70BF43; /* G40 */
$color-secondary-yellow: #FADE71; /* Y40 */
$color-secondary-red: #D90429; /* R40 */
$color-secondary-brblue: #3385FF; /* BRB50 */

$color-tertiary-bblue: #5D7DD1; /* BB50 */
$color-tertiary-sblue: #BFD7EA; /* SB40 */
$color-tertiary-orange: #FAA61A; /* O40 */
$color-tertiary-ored: #FF5A5F; /* OR40 */

$color-neutral-blue-80: #3C4368; /* NB80 */
$color-neutral-blue-60: #5E6483; /* NB60 */
$color-neutral-blue-40: #A3A6B8; /* NB40 */
$color-neutral-blue-30: #BABDCA; /* NB30 */
$color-neutral-blue-15: #D1D3DC; /* NB15 */
$color-neutral-blue-10: #E8E9ED; /* NB10 */

$color-dark-blue-40: #5A6991; /* DB40 */
$color-dark-blue-30: #919BB6; /* DB30 */
$color-dark-blue-20: #C8CDDB; /* DB20 */

$color-bright-blue-70: #235BAF; /* BRB70 */
$color-bright-blue-60: #2A6ED3; /* BRB60 */
$color-bright-blue-40: #66A4FF; /* BRB40 */
$color-bright-blue-30: #99C2FF; /* BRB30 */
$color-bright-blue-20: #CCE1FF; /* BRB20 */
$color-bright-blue-15: #E6F0FF; /* BRB15 */
$color-bright-blue-10: #F3F8FF; /* BRB10 */

$color-green-70: #559032; /* G70 */
$color-green-60: #5D9E37; /* G60 */
$color-green-50: #66AE3D; /* G50 */
$color-green-30: #94CF72; /* G30 */
$color-green-20: #B8DFA1; /* G20 */
$color-green-10: #DCEFD0; /* G10 */

$color-yellow-70: #BBA755; /* Y70 */
$color-yellow-60: #CEB85E; /* Y60 */
$color-yellow-50: #E3CA67; /* Y50 */
$color-yellow-30: #FAE695; /* Y30 */
$color-yellow-20: #FDF3CA; /* Y20 */
$color-yellow-10: #FEF9E5; /* Y10 */

$color-red-70: #A3041F; /* R70 */
$color-red-60: #B30422; /* R60 */
$color-red-50: #C50425; /* R50 */
$color-red-30: #EC8294; /* R30 */
$color-red-20: #F6C1CA; /* R20 */
$color-red-10: #FBE0E5; /* R10 */

$color-beau-blue-80: #2F3F69; /* BB80 */
$color-beau-blue-70: #465E9D; /* BB70 */
$color-beau-blue-40: #AEBEE8; /* BB40 */
$color-beau-blue-30: #D7DFF4; /* BB30 */
$color-beau-blue-20: #EBEFFA; /* BB20 */
$color-beau-blue-10: #F5F7FD; /* BB10 */

$color-sky-blue-60: #9EB1C2; /* SB60 */
$color-sky-blue-50: #AEC3D5; /* SB50 */
$color-sky-blue-30: #DFEBF5; /* SB30 */
$color-sky-blue-20: #EFF5FA; /* SB20 */
$color-sky-blue-10: #F7FAFD; /* SB10 */

$color-blue-70: #577DB6; /* B70 */
$color-blue-60: #6F90C0; /* B60 */
$color-blue-50: #87A2CA; /* B50 */
$color-blue-40: #9FB5D5; /* B40 */
$color-blue-20: #CFDAEA; /* B20 */
$color-blue-10: #E7ECF5; /* B10 */

$color-orange-70: #BB7D14; /* O70 */
$color-orange-60: #CE8916; /* O60 */
$color-orange-50: #E39718; /* O50 */
$color-orange-30: #FDD38D; /* O30 */
$color-orange-20: #FEE9C6; /* O20 */
$color-orange-10: #FFF4E3; /* O10 */

$color-orange-red-70: #C04447; /* OR70 */
$color-orange-red-60: #D34B4E; /* OR60 */
$color-orange-red-50: #E85256; /* OR50 */
$color-orange-red-30: #FFADAF; /* OR30 */
$color-orange-red-20: #FFD6D7; /* OR20 */
$color-orange-red-10: #FFEBEB; /* OR10 */

