@use '--colors.module.scss' as color;

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf')  format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf')  format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf')  format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'), 
        url('../fonts/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins-Bold.ttf')  format('truetype');
    font-weight: 800;
}

/* TYPOGRAPHY BEGIN */
h1, h2, h3, h4, h5 { margin: 0; }
h1 {
    font-weight: 700;
    font-size: 46px;
    line-height: 0.9;
} /* main titles H800 */
h1.extra {
    font-weight: 800;
    font-size: 66px;
    line-height: 1.3;
} /* Oversized screen titles H900 */
h2 {
    font-weight: 800;
    font-size: 34px;
    line-height: 1.18;
} /* Top level headers, admin panel main titles and feature introductions H700 */
h3 {
    font-weight: normal;
    font-size: 30px;
    line-height: 1.25;
} /* Key functionality headings H600 */
h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
} /* Form section headings H500 */
h5 {
    font-weight: 800;
    font-size: 18px;
    line-height: 1.3;
} /* Deep headings H400 */
.txt-sub-headline {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
} /* Subtitles H300 */
.txt-form-group-headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
} /* Field group headings, admin panel nav submenu title H200 */
.txt-highlighted-text {
    font-weight: 700;
    font-size: 14px;
} /* Highlighted text H100 */
.txt-small {
    font-size: 12px;
    line-height: 1.3;
} /* Small paragraph, secondary text P100 */
/* TYPOGRAPHY END */

body {
    background: #F5F6FA;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    color: color.$color-primary-text;
    font-size: 14px;
    line-height: 1.4;
}
a {
    color: color.$color-secondary-brblue;
    text-decoration: none;
    &:hover { color: color.$color-bright-blue-60; }
}
hr {
    border: 0;
    height: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
}

main { 
    padding: 1rem 1rem 4rem 1rem;
    h2:first-child { margin: 2rem 0; }
}
.mainContent {
    background: #ffffff;
    border: 1px solid color.$color-neutral-blue-15;
    border-radius: 6px;
    padding: 40px;
    margin-top: 22px;
}

.MuiButton-root {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
}
.MuiButton-outlined {
    background: #ffffff;
    border-color: #B2B5C2;
    color: color.$color-neutral-blue-60;
    &:hover, &:focus {
        @extend .MuiButton-outlined;
        border-color: #999CAE;
        color: color.$color-primary-text;
    }
    &:active {
        background: color.$color-bright-blue-10;
        border-color: #B2B5C2;
    }
}
.MuiButton-containedPrimary { 
    background-color: color.$color-secondary-brblue; 
    box-shadow: none;
    &:hover, &:focus {
        background-color: color.$color-bright-blue-60;
        box-shadow: none;
    }
    &:active { background-color: color.$color-bright-blue-70; }
    &.MuiButton-sizeMedium { padding: 8px 18px; }
    &.Mui-disabled {
        background: color.$color-bright-blue-30;
        color: #ffffff;
    }
}
/* BUTTONS END */
a { 
    color: color.$color-secondary-brblue;
    text-decoration: none;
    &:hover { text-decoration: underline; }
}
.status {
    background: color.$color-neutral-blue-10;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: color.$color-neutral-blue-80;
    padding: 2px 6px;
    border-radius: 4px;
    margin: auto 0.2rem;
    &--good {
        background-color: color.$color-green-10;
        color: color.$color-green-70;
    }
}
form.MuiBox-root { justify-content: space-between; }
.MuiInputBase-root, .MuiSelect-outlined {
    &:hover .MuiOutlinedInput-notchedOutline { border-color: color.$color-neutral-blue-40; }
}
.MuiOutlinedInput-notchedOutline { border-color: #DCDEE4; }
input[type="text"], input[type="email"], input[type="password"], input[type="phone"], input[type="checkbox"], input[type="radio"], select, textarea, .MuiSelect-outlined {background-color: #ffffff;}
.MuiCheckbox-root { 
    color: color.$color-neutral-blue-30;
    &.Mui-checked { color: color.$color-tertiary-bblue; }
}
.MuiCheckbox-colorPrimary:hover { color: color.$color-neutral-blue-40; }